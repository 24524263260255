<template>
  <main>
    <section class="section">
      <div class="container">
        <div class="hero">
          <h1 class="hero__title center">Contact</h1>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="contact-grid">
          <div class="contacts">
            <h4 class="contacts__title">Head Office</h4>
            <ul class="contacts__list">
              <li class="contacts__item">
                <i class="ri-map-pin-fill contacts__icon"></i>
                <!--7278 Road 116, Linwood, ON N0B 2A0-->
                <div class="contacts__info">427561 8th Concession A, Flesherton, ON N0C 1E0</div>
              </li>
              <li class="contacts__item">
                <i class="ri-phone-fill contacts__icon"></i>
                <a href="tel:+15199223041" class="contacts__info contacts__info--link">(519) 922 3041</a>
              </li>
              <li class="contacts__item">
                <i class="ri-mail-fill contacts__icon"></i>
                <a href="mailto:sales@fleshertonweldingsupply.ca" class="contacts__info contacts__info--link">
                  sales@fleshertonweldingsupply.ca
                </a>
              </li>
            </ul>
          </div>
          <div class="map-wrap">
            <div class="map"><GoogleMap /></div>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container"><Form /></div>
    </section>
    <section class="section">
      <div class="container">
        <ul class="brands">
          <li class="brands__item">
            <a href="#" class="brands__link" target="blank">
              <img src="@/assets/images/tweco.png" alt="Long Lane Welding Supply Brands" class="brands__logo" />
            </a>
          </li>
          <li class="brands__item">
            <a href="#" class="brands__link" target="blank">
              <img src="@/assets/images/alcotec.png" alt="Long Lane Welding Supply Brands" class="brands__logo" />
            </a>
          </li>
          <li class="brands__item">
            <a href="#" class="brands__link" target="blank">
              <img src="@/assets/images/alcair.png" alt="Long Lane Welding Supply Brands" class="brands__logo" />
            </a>
          </li>
          <li class="brands__item">
            <a href="#" class="brands__link" target="blank">
              <img src="@/assets/images/stoody.png" alt="Long Lane Welding Supply Brands" class="brands__logo" />
            </a>
          </li>
          <li class="brands__item">
            <a href="#" class="brands__link" target="blank">
              <img
                src="@/assets/images/thermal-dynamics.png"
                alt="Long Lane Welding Supply Brands"
                class="brands__logo"
              />
            </a>
          </li>
          <li class="brands__item">
            <a href="#" class="brands__link" target="blank">
              <img src="@/assets/images/turbotorch.png" alt="Long Lane  Welding Supply Brands" class="brands__logo" />
            </a>
          </li>
        </ul>
      </div>
    </section>
  </main>
</template>
<script>
import GoogleMap from '@/views/Customer/components/GoogleMap'
import Form from '@/views/Customer/components/Form'
export default {
  components: {GoogleMap, Form},
  created() {
    window.scrollTo(0, 0)
  },
  metaInfo: {
    title: 'Contact | Flesherton Welding Supply',
    meta: [
      {
        name: 'description',
        content: 'Get in touch to check product availability and see how we can work together.'
      }
    ]
  }
}
</script>
<style lang="scss" scoped>
@import 'style';
</style>
