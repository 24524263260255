<template>
  <gmap-map v-bind="options" id="map">
    <GmapMarker
      v-for="(m, index) in markers"
      :key="index"
      :position="m.position"
      :clickable="true"
      :draggable="true"
      :icon="{url: require('@/assets/icons/location-map.svg')}"
      @click="center = m.position"
    />
  </gmap-map>
</template>
<script>
import {gmapApi} from 'vue2-google-maps'

export default {
  name: 'GoogleMap',
  data() {
    return {
      options: {
        zoom: 17,
        center: {
          lat: Number(44.326714),
          lng: Number(-80.408562)
        }
      },
      markers: [
        {
          position: {
            lat: Number(44.326714),
            lng: Number(-80.408562)
          }
        }
      ]
    }
  },
  computed: {
    google: gmapApi
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';
#map {
  width: 100%;
  height: 235px;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
  filter: sepia(40%);
}
</style>
